
.btn-expand{
    overflow: hidden;
    width: 25%;
    transition: width 0.75s cubic-bezier(0.000, 0.795, 0.000, 1.000)
}
.btn-click{
    overflow: hidden;
    background-color: red;
    white-space: nowrap;
    width: 40%;

}

.btn-expand[id='1']{
    width: 60%;
    transition: width 0.75s cubic-bezier(0.000, 0.795, 0.000, 1.000)
    
}
.btn-hidden{
    background-color: blue;
}




.a-btn{
    background:#80a9da;
    background:-webkit-gradient(linear,left top,left bottom,color-stop(#80a9da,0),color-stop(#6f97c5,1));
    background:-webkit-linear-gradient(top, #80a9da 0%, #6f97c5 100%);
    background:-moz-linear-gradient(top, #80a9da 0%, #6f97c5 100%);
    background:-o-linear-gradient(top, #80a9da 0%, #6f97c5 100%);
    background:linear-gradient(top, #80a9da 0%, #6f97c5 100%);
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#80a9da', endColorstr='#6f97c5',GradientType=0 );
    padding-left:20px;
    padding-right:80px;
    height:38px;
    display:inline-block;
    position:relative;
    border:1px solid #5d81ab;
    -webkit-box-shadow:0px 1px 1px rgba(255,255,255,0.8) inset, 1px 1px 3px rgba(0,0,0,0.2), 0px 0px 0px 4px rgba(188,188,188,0.5);
    -moz-box-shadow:0px 1px 1px rgba(255,255,255,0.8) inset, 1px 1px 3px rgba(0,0,0,0.2), 0px 0px 0px 4px rgba(188,188,188,0.5);
    box-shadow:0px 1px 1px rgba(255,255,255,0.8) inset, 1px 1px 3px rgba(0,0,0,0.2), 0px 0px 0px 4px rgba(188,188,188,0.5);
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    border-radius:20px;
    float:left;
    clear:both;
    margin:10px 0px;
    overflow:hidden;
    -webkit-transition:all 0.3s linear;
    -moz-transition:all 0.3s linear;
    -o-transition:all 0.3s linear;
    transition:all 0.3s linear;
}
.a-btn-text{
    padding-top:5px;
    display:block;
    font-size:18px;
    white-space:nowrap;
    text-shadow:0px 1px 1px rgba(255,255,255,0.3);
    color:#446388;
    -webkit-transition:all 0.2s linear;
    -moz-transition:all 0.2s linear;
    -o-transition:all 0.2s linear;
    transition:all 0.2s linear;
}
.a-btn-slide-text{
    position:absolute;
    height:100%;
    top:0px;
    right:52px;
    width:0px;
    background:#63707e;
    text-shadow:0px -1px 1px #363f49;
    color:#fff;
    font-size:18px;
    white-space:nowrap;
    text-transform:uppercase;
    text-align:left;
    text-indent:10px;
    overflow:hidden;
    line-height:38px;
    -webkit-box-shadow:-1px 0px 1px rgba(255,255,255,0.4), 1px 1px 2px rgba(0,0,0,0.2) inset;
    -moz-box-shadow:-1px 0px 1px rgba(255,255,255,0.4), 1px 1px 2px rgba(0,0,0,0.2) inset;
    box-shadow:-1px 0px 1px rgba(255,255,255,0.4), 1px 1px 2px rgba(0,0,0,0.2) inset;
    -webkit-transition:width 0.3s linear;
    -moz-transition:width 0.3s linear;
    -o-transition:width 0.3s linear;
    transition:width 0.3s linear;
}
.a-btn-icon-right{
    position:absolute;
    right:0px;
    top:0px;
    height:100%;
    width:52px;
    border-left:1px solid #5d81ab;
    -webkit-box-shadow:1px 0px 1px rgba(255,255,255,0.4) inset;
    -moz-box-shadow:1px 0px 1px rgba(255,255,255,0.4) inset;
    box-shadow:1px 0px 1px rgba(255,255,255,0.4) inset;
}
.a-btn-icon-right span{
    width:38px;
    height:38px;
    opacity:0.7;
    position:absolute;
    left:50%;
    top:50%;
    margin:-20px 0px 0px -20px;
    -webkit-transition:all 0.3s linear;
    -moz-transition:all 0.3s linear;
    -o-transition:all 0.3s linear;
    transition:all 0.3s linear;
}
.a-btn:hover{
    padding-right:80px;
    -webkit-box-shadow:0px 1px 1px rgba(255,255,255,0.8) inset, 1px 1px 3px rgba(0,0,0,0.2);
    -moz-box-shadow:0px 1px 1px rgba(255,255,255,0.8) inset, 1px 1px 3px rgba(0,0,0,0.2);
    box-shadow:0px 1px 1px rgba(255,255,255,0.8) inset, 1px 1px 3px rgba(0,0,0,0.2);
}
.a-btn:hover .a-btn-text{
    text-shadow:0px 1px 1px #5d81ab;
    color:#fff;
}
.a-btn:hover .a-btn-slide-text{
    width:100px;
}
.a-btn:hover .a-btn-icon-right span{
    opacity:1;
}
.a-btn:active{
    position:relative;
    top:1px;
    background:#5d81ab;
    -webkit-box-shadow:1px 1px 2px rgba(0,0,0,0.4) inset;
    -moz-box-shadow:1px 1px 2px rgba(0,0,0,0.4) inset;
    box-shadow:1px 1px 2px rgba(0,0,0,0.4) inset;
    border-color:#80a9da;
}
