/* unvisited link */
a{
  text-decoration:underline;
  color: '#93A4DE';
    font-weight: 700;
}

/*  link red */
a:link {
    color: '#93A4DE';
    font-weight: 700;
  }
  
  /* visited link green */
  a:visited {
    color: '#93A4DE';
  }
  
  /* mouse over link hotpink*/
  a:hover {
    color: '#93A4DE';
  }
  
  /* selected link blue */
  a:active {
    color: '#93A4DE';
  }