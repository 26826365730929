@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,500,700);

html {
  font-size: 16px;
  line-height: 25px;
}

/* applies to GIF and PNG images; avoids blurry edges */
img[src$=".gif"], img[src$=".png"] {
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
}

@media screen and (max-width: 1700px) {
  html {
    font-size: 13px;
  }

}

@media screen and (max-width: 1400px) {
  html {
    font-size: 11px;
  }

}

@media screen and (max-width: 900px) {
  html {
    font-size: 9px;
  }

}

body {
  background-color: black;
  margin: 0;
  overflow: hidden;
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* To fix overflow Burger Menu*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

*:before,
*:after {
  box-sizing: border-box;
}

#page-wrap {

  max-width: 100%;
  margin: auto;
  overflow: hidden;
}

#outer-container {
  min-height: 100vh;
  /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
}


/*Play Pandemic*/

/*FireFox fix*/


/* Slider width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #DFD4CB;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* unvisited link */
a {
  text-decoration: underline;
  color: #4c67c2;
  font-weight: 700;
}

/*  link red */
a:link {
  color: #4c67c2;
  font-weight: 700;
}

/* visited link green */
a:visited {
  color: #4c67c2;
}

/* mouse over link hotpink*/
a:hover {
  color: #4c67c2;
}

/* selected link blue */
a:active {
  color: #4c67c2;
}